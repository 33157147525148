@import "../App.scss";

.device {
  .device__hero {
    width: 100%;
    height: 60vh;
    overflow: hidden;
    padding: 0 12.5%;
    @include flex(row, center, center);

    @media only screen and (max-width: 1300px) {
      padding: 0 5%;
    }

    @media only screen and (max-width: 1000px) {
      height: 100%;
      @include flex(column, center, center);
    }
    .image {
      width: 50%;
      height: 100%;
      overflow: hidden;
      position: relative;
      border-top: none;

      @media only screen and (max-width: 1000px) {
        width: 100%;
        height: 50%;
        border-top: 1px solid $gray;
      }

      img {
        width: 100%;
        height: 100% !important;
        object-fit: cover;
        object-position: center;
      }
    }

    .content {
      @include flex(column, center, flex-start);
      padding-left: 5%;
      width: 50%;
      height: 100%;
      gap: 1rem;

      @media only screen and (max-width: 1000px) {
        width: 100%;
        height: 50%;
        padding: 5vh 5%;
      }

      h1 {
        font-size: 3.2rem;
        color: $black;
        margin-bottom: 2rem;

        @media only screen and (max-width: 1300px) {
          font-size: 2.5rem;
          width: 100%;
          text-align: left;
        }

        span {
          color: $primary;
        }
      }

      p {
        font-size: 0.9rem;
        color: $black;
        max-width: 70%;
        text-align: left;

        @media only screen and (max-width: 1300px) {
          font-size: 0.85rem;
          max-width: 100%;
        }
      }

      a {
        border-bottom: 1px solid $black;
        color: $black;
        margin-top: 1rem;
        font-size: 0.9rem;
        cursor: pointer;
      }
    }
  }

  .device__specifications {
    width: 75%;
    margin: 5vh 12.5%;
    @include flex(column, flex-start, flex-start);
    border: 1px solid $gray;

    @media only screen and (max-width: 1300px) {
      width: 90%;
      margin: 5vh 5%;
    }

    .item {
      @include flex(row, flex-start, center);
      width: 100%;
      border-bottom: 1px solid $gray;

      &:last-child {
        border-bottom: none;
      }

      .title {
        padding: 0.5rem 1rem;
        width: 30%;
        font-size: 0.92rem;
        border-right: 1px solid $gray;
      }

      .value {
        padding: 0.5rem 1rem;
        width: 70%;
        font-size: 0.92rem;
      }
    }
  }

  .device__content {
    @include flex(column, center, center);
    width: 100%;
    padding: 5vh 12.5%;
    overflow: hidden;
    gap: 5vh;
    @media only screen and (max-width: 1300px) {
      padding: 5vh 5%;
    }
    .slide {
      height: 60vh;
      width: 100%;
      @include flex(row, center, center);
      @media only screen and (max-width: 1300px) {
        @include flex(column, center, center);
        height: auto;
      }

      .image {
        width: 50%;
        height: 60vh;
        overflow: hidden;
        @media only screen and (max-width: 1300px) {
          width: 100%;
          height: 50vh;
        }

        img {
          width: 100%;
          height: 100% !important;
          object-fit: contain;
          object-position: center;
        }
      }

      .content {
        @include flex(column, center, flex-start);
        width: 50%;
        padding-left: 5%;
        height: 100%;
        @media only screen and (max-width: 1300px) {
          width: 100%;
          height: auto;
          padding: 5vh 5%;
        }

        h1 {
          font-size: 3.2rem;
          color: $black;
          margin-bottom: 2rem;
          text-align: left;

          @media only screen and (max-width: 1300px) {
            font-size: 2.5rem;
            text-align: center;
            max-width: 100%;
          }
        }

        p {
          font-size: 0.95rem;
          color: $black;
          max-width: 100%;
          text-align: left;
          margin-top: 0.5rem;

          @media only screen and (max-width: 1300px) {
            font-size: 0.9rem;
            max-width: 100%;
          }
        }
      }

      &.reverse {
        @include flex(row-reverse, center, center);
        @media only screen and (max-width: 1300px) {
          @include flex(column, center, center);
        }

        .content {
          padding-right: 5%;
          padding-left: 0;
        }
      }
    }
  }

  .video {
    width: 100%;
    padding: 5vh 12.5%;
    @include flex(column, center, center);
    overflow: hidden;

    video {
      width: 100%;
      object-fit: cover;
      object-position: center;
      @media only screen and (max-width: 1300px) {
        height: 50vh;
      }
    }
  }

  .devices {
    @include flex(column, center, center);
    width: 100%;
    padding: 5vh 12.5%;
    overflow: hidden;

    @media only screen and (max-width: 1300px) {
      padding: 5vh 5%;
    }

    h1 {
      font-size: 2.6rem;
      color: $dark-gray;
      margin-bottom: 2rem;
      text-transform: uppercase;

      @media only screen and (max-width: 1300px) {
        font-size: 2.5rem;
        width: 100%;
        text-align: center;
        margin-bottom: 1rem;
      }

      span {
        color: $primary;
      }
    }

    p {
      text-align: center;
      max-width: 40%;
      font-size: 0.85rem;
      @media only screen and (max-width: 1300px) {
        max-width: 90%;
      }
    }

    .devices__container {
      @include flex(row, center, center);
      gap: 1rem;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 3rem;
      overflow: hidden;

      @media only screen and (max-width: 1300px) {
        gap: 0.5rem;
        margin-top: 2rem;
      }

      .device {
        width: calc(50% - 0.5rem);
        height: 30vh;
        overflow: hidden;
        @include flex(row, center, center);
        background-color: #f1f1f1;
        border: 1px solid #e0e0e0;

        @media only screen and (max-width: 1300px) {
          width: calc(50% - 0.5rem);
        }

        @media only screen and (max-width: 1000px) {
          width: calc(100%);
        }

        .image {
          width: 50%;
          height: 100%;
          overflow: hidden;
          position: relative;

          img {
            width: 100%;
            height: 100% !important;
            object-fit: cover;
            object-position: center;
          }
        }

        .content {
          height: 100%;
          width: 50%;
          padding: 1.5rem;
          @include flex(column, space-between, flex-start);

          .top {
            @include flex(column, flex-start, flex-start);
            width: 100%;

            h3 {
              font-size: 2.2rem;
              color: $black;
              margin-bottom: 0.5rem;
            }

            p {
              font-size: 0.9rem;
              color: $dark-gray;
              max-width: 100%;
              text-align: left;
            }
          }

          .bottom {
            @include flex(row, flex-start, center);
            width: 100%;
            gap: 1rem;

            @media only screen and (max-width: 1300px) {
              gap: 0.5rem;
              @include flex(column-reverse, flex-start, flex-start);
            }

            .outside__btn {
              font-size: 0.83rem;
              background-color: $dark-gray;
              color: $white;
              padding: 0.65rem 1.1rem;
            }
          }
        }
      }
    }
  }
}
