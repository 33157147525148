@import "../App.scss";

.navbar {
  width: 100%;
  height: 10vh;
  position: fixed;
  @include flex(row, space-between, center);
  padding: 1vh 0;
  z-index: 1;
  background: transparent;
  gap: 2rem;
  position: fixed;
  top: 0;
  z-index: 100000000000;
  margin-right: 12.5%;
  border-bottom: 1px solid rgba($color: $white, $alpha: 0.25);
  transition: all 0.3s ease-in-out;

  @media (max-width: 768px) {
    padding: 0;
  }

  &.scrolled {
    background-color: $dark-gray;
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.25);
    height: 8vh;

    .btn {
      font-size: 0.9rem;
    }
  }

  .navbar__left {
    height: 100%;
    z-index: 3;
    @include flex(row, flex-start, center);
    padding-left: 12.5%;
    padding-right: 3rem;
    gap: 3rem;

    @media (max-width: 1300px) {
      @include flex(row, center, center);
    }

    @media (max-width: 768px) {
      padding: 0.1rem;
      width: 50%;
    }

    .logo {
      height: 100%;
      width: 100%;
      cursor: pointer;
      padding: 0.2rem 0;

      @media (max-width: 1300px) {
        padding: 0.3rem 5%;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .navbar__center {
    @include flex(row, center, center);
    height: 100%;
    gap: 3rem;

    @media (max-width: 1100px) {
      display: none;
    }

    a {
      text-decoration: none;
      color: $light-gray;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 300;
      width: auto;
      @include flex(row, center, center);
      height: 100%;
      transition: all 0.2s ease-in-out;

      &:hover,
      &.active {
        color: $white;
      }
    }
  }

  .navbar__right {
    @include flex(row, flex-end, center);
    height: 100%;
    gap: 2rem;
    padding-right: 12.5%;

    @media (max-width: 1100px) {
      padding-right: 20%;
    }

    .info {
      @include flex(row, center, center);
      gap: 1rem;
      @media (max-width: 1400px) {
        display: none;
      }

      a {
        @include flex(row, center, center);
        gap: 0.5rem;
        text-decoration: none;
        color: $light-gray;

        svg {
          height: 2.3vh;
          width: 2.3vh;
          color: rgba($color: $white, $alpha: 0.6);
        }

        span {
          font-size: 0.9rem;
          font-weight: 200;
          color: $light-gray;
        }
      }
    }

    .btn {
      padding: 0.65rem 1.1rem;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 500;
      border: 1px solid $white;
      background-color: transparent;
      @include flex(row, center, center);
    }

    .menu__btn {
      @include flex(row, flex-end, center);
      height: 100%;
      cursor: pointer;
      display: none;
      background-color: transparent;
      border: none;
      padding: 0 0.5rem;

      svg {
        height: 3vh;
        width: 3vh;
        color: $light-gray;
      }
    }

    .socials {
      @include flex(row, center, center);
      gap: 1rem;

      @media (max-width: 1100px) {
        display: none;
      }

      .social {
        border-radius: 50%;
        padding: 0.2rem;
        border: 1px solid rgba($color: #e7e7e7, $alpha: 0.3);
        background-color: rgba($color: #e9e9e9, $alpha: 0.05);
        width: 2.3rem;
        height: 2.3rem;
        @include flex(row, center, center);
        cursor: pointer;

        img {
          height: 100%;
          width: 100%;
          opacity: 0.7;
        }

        &:hover {
          img {
            opacity: 1;
          }
        }
      }
    }

    @media (max-width: 1100px) {
      .menu__btn {
        display: flex;
      }

      .btn {
        display: none;
      }
    }

    @media (max-width: 768px) {
      padding-right: 5%;
    }
  }

  &.scrolled {
    background-color: $dark-gray;
    border-bottom: 1px solid rgba($color: $white, $alpha: 0.25);
    height: 8vh;

    .btn {
      font-size: 0.85rem;
    }

    .socials {
      .social {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  &.black__background {
    background-color: $dark-gray;
    height: 8vh;

    .btn {
      font-size: 0.9rem;
    }

    .socials {
      .social {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}

.mobile__menu {
  @include flex(column, flex-start, center);
  position: fixed;
  top: 0;
  left: -1px;
  width: 70%;
  height: 100%;
  background-color: rgba($color: $white, $alpha: 1);
  z-index: 10000000000;
  transform: translateX(-100%);
  opacity: 0;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;

  .top {
    width: 100%;
    padding-top: 1rem;
    @include flex(column, flex-start, flex-start);
    background-color: $dark-gray;

    .logo {
      width: 70%;
      cursor: pointer;
      margin-bottom: 0.75rem;
      margin-top: 0.75rem;
      margin-left: 1rem;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    p {
      width: 100%;
      background-color: $white;
      padding: 2rem 1rem;
      font-size: 0.9rem;
      font-weight: 400;
      color: $black;
    }
  }

  .links {
    width: 100%;
    @include flex(column, flex-start, flex-start);

    a {
      text-decoration: none;
      color: $black;
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 500;
      @include flex(row, flex-start, center);
      width: 100%;
      padding: 1rem 1rem;
      transition: all 0.2s ease-in-out;
      border-bottom: 1px solid #e9e9e9;
      &:nth-child(1) {
        border-top: 1px solid #e9e9e9;
      }

      &.active,
      &:hover {
        background-color: rgba($color: $black, $alpha: 0.05);
      }
    }
  }

  .info {
    @include flex(column, flex-start, flex-start);
    width: 100%;

    a {
      @include flex(row, flex-start, center);
      gap: 0.5rem;
      text-decoration: none;
      color: $dark-gray;
      padding: 1rem 1rem;
      border-bottom: 1px solid #e9e9e9;
      width: 100%;
      svg {
        height: 2.3vh;
        width: 2.3vh;
        color: $dark-gray;
      }

      span {
        font-size: 0.9rem;
        font-weight: 300;
        color: $dark-gray;
      }
    }
  }

  .bottom {
    width: 100%;
    @include flex(column, center, center);
    padding: 0;
    gap: 1rem;
    position: absolute;
    bottom: 0rem;

    .socials {
      @include flex(row, center, center);
      gap: 1rem;
      width: 100%;

      .social {
        border-radius: 50%;
        padding: 0.2rem;
        border: 1px solid rgba($color: #000000, $alpha: 0.3);
        background-color: rgba($color: #000000, $alpha: 0.05);
        width: 2.3rem;
        height: 2.3rem;
        @include flex(row, center, center);
        cursor: pointer;

        img {
          height: 100%;
          width: 100%;
          opacity: 0.9;
        }

        &:hover {
          img {
            opacity: 1;
          }
        }
      }
    }

    .rights {
      width: 100%;
      padding: 0.6rem 0;
      width: 100%;
      background-color: $black;
      @include flex(row, center, center);
    }

    p {
      font-size: 0.75rem;
      color: $light-gray;
      font-weight: 200;
      text-align: center;
    }
  }

  &.show {
    transform: translateX(0%);
    opacity: 1;
  }

  .close__btn {
    position: absolute;
    border: none;
    outline: none;
    left: 100%;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10000000000;
    display: none;
    background-color: rgba($color: $black, $alpha: 0.3);
    backdrop-filter: blur(3px);
    animation: fadeIn 0.5s ease-in-out;

    &.show {
      display: block;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
