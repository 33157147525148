@import "../App.scss";

.devices {
  .devices {
    @include flex(column, center, center);
    width: 100%;
    padding: 3vh 12.5%;
    transform: translateY(-6vh);

    @media only screen and (max-width: 1300px) {
      padding: 5vh 5%;
    }

    .devices__container {
      @include flex(row, center, center);
      gap: 1rem;
      flex-wrap: wrap;
      width: 100%;

      @media only screen and (max-width: 1300px) {
        gap: 0.5rem;
      }

      .device {
        width: calc(50% - 0.5rem);
        height: 30vh;
        overflow: hidden;
        @include flex(row, center, center);
        background-color: #f1f1f1;
        border: 1px solid #e0e0e0;

        @media only screen and (max-width: 1300px) {
          width: calc(50% - 0.5rem);
        }

        @media only screen and (max-width: 1000px) {
          width: calc(100%);
        }

        .image {
          width: 50%;
          height: 100%;
          overflow: hidden;
          position: relative;

          img {
            width: 100%;
            height: 100% !important;
            object-fit: cover;
            object-position: center;
          }
        }

        .content {
          height: 100%;
          width: 50%;
          padding: 1.5rem;
          @include flex(column, space-between, flex-start);

          .top {
            @include flex(column, flex-start, flex-start);
            width: 100%;

            h3 {
              font-size: 2.2rem;
              color: $black;
              margin-bottom: 0.5rem;
            }

            p {
              font-size: 0.9rem;
              color: $dark-gray;
              max-width: 100%;
              text-align: left;
            }
          }

          .bottom {
            @include flex(row, flex-start, center);
            width: 100%;
            gap: 1rem;

            @media only screen and (max-width: 1300px) {
              gap: 0.5rem;
              @include flex(column-reverse, flex-start, flex-start);
            }

            .outside__btn {
              font-size: 0.83rem;
              background-color: $dark-gray;
              color: $white;
              padding: 0.65rem 1.1rem;
            }
          }
        }
      }
    }
  }
}
