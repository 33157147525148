@import "../App.scss";

.projects {
  .projects__container {
    @include flex(row, center, flex-start);
    width: 100%;
    padding: 3vh 12.5%;
    flex-wrap: wrap;
    transform: translateY(-6vh);

    @media (max-width: 1600px) {
      padding: 3vh 5%;
      gap: 0.5rem;
    }

    .project {
      width: calc(75vw / 3 - 0.5rem);
      height: 75vh;
      @include flex(column, flex-start, flex-start);
      padding: 0 0.5rem;
      position: relative;
      gap: 0;
      text-decoration: none;
      color: $dark-gray;

      &:hover {
        .image {
          img {
            transition: all 0.3s ease-in-out;
            transform: scale(1.1);
          }
        }
      }

      @media (max-width: 1300px) {
        width: calc(90vw / 3);
      }

      @media (max-width: 1000px) {
        width: calc(90vw / 2);
      }

      @media (max-width: 768px) {
        width: 90vw;
        height: 85vh;
      }

      @media (max-width: 400px) {
        height: 92vh;
      }

      .image {
        height: 60%;
        width: 100%;
        overflow: hidden;
        position: relative;
        border-radius: 0.2rem;
        box-shadow: 0 0 6px rgba($color: $black, $alpha: 0.25);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .content {
        @include flex(column, flex-start, flex-start);
        width: calc(100%);
        gap: 1rem;
        height: 40%;
        padding: 1.5rem 0;
        padding-bottom: 0;
        position: relative;

        h3 {
          font-size: 23px;
          color: $dark-gray;

          @media (max-width: 1300px) {
            font-size: 18px;
          }
        }

        .project__type {
          padding: 0.25rem 0.7rem;
          background-color: #dfdfdf;
          color: #3d3d3d;
          font-size: 13px;
          font-weight: 600;
          z-index: 10;
          border-radius: 0.3rem;
        }

        p {
          font-size: 12px;
          color: $dark-gray;
          max-width: 100%;
          text-align: left;
          line-height: 1.5;
          padding-bottom: 2rem;
          position: relative;
        }

        .outside__btn {
          position: absolute;
          bottom: 0;
          font-size: 0.85rem;
          background-color: $dark-gray;
          color: $white;
          padding: 0.6rem 1.2rem;
          margin-top: 0.5rem;

          @media (max-width: 1300px) {
            font-size: 0.78rem;
          }
        }
      }
    }
  }
}
