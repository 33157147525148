$primary: #000000;
$primary-dark: #420000;
$primary-light: #420000;
$secondary: #111111;
$secondary-dark: #0f1618;
$white: #fff;
$black: #000;
$light-gray: #f5f5f5;
$gray: #ccc;
$dark-gray: rgb(31, 31, 31);
$gray-1: #111;
$gray-2: #222;
$gray-3: #333;
$gray-4: #444;
$gray-5: #555;
$gray-6: #666;
$gray-7: #777;
$gray-8: #888;
$gray-9: #999;
$gray-10: #aaa;
$gray-11: #bbb;
$gray-12: #ccc;
$gray-13: #ddd;
$gray-14: #eee;
$gray-15: #f6f6f6;

* {
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

:root {
  font-family: Inter, sans-serif;
  font-feature-settings: "liga" 1, "calt" 1; /* fix for Chrome */
}

body {
  margin: 0;
  font-size: 1rem;
  color: $black;
  width: 100%;
  position: relative;
  overflow-x: hidden !important;
  overflow-y: auto;
}

.btn {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: rgba($color: $primary, $alpha: 1);
  color: $white;
  padding: 0.6rem 1.2rem;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-size: 0.85rem;
  font-weight: 500;
  position: relative;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background-color: rgba($color: $white, $alpha: 0.25);
    left: 0;
    top: 0;
    transition: all 0.1s ease-in-out;
    z-index: 1;
  }

  &:hover {
    &::before {
      width: 100%;
    }
  }
}

.outside__btn {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  color: $white;
  background-color: $secondary;
  padding: 0.5rem 1.1rem;
  font-size: 1rem;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  font-size: 1rem;
  position: relative;
  border-radius: 0.1rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 0.4rem 0.9rem;
  }

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    background-color: rgba($color: $white, $alpha: 0.25);
    left: 0;
    top: 0;
    transition: all 0.1s ease-in-out;
    z-index: 1;
  }

  &:hover {
    &::before {
      width: 100%;
    }
  }
}

.link {
  color: $light-gray;
  font-size: 0.95rem;
  text-decoration: none;
  border-bottom: 1px solid $light-gray;
}

.page {
  width: 100%;
  min-height: 80vh;
  position: relative;
}

@mixin flex($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

.page__hero {
  height: 40vh;
  width: 100%;
  position: relative;
  overflow: hidden;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      display: block;
      filter: brightness(0.4);
    }
  }

  h1 {
    font-size: 3rem;
    font-weight: 700;
    color: $white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;

    @media (max-width: 768px) {
      width: 90%;
      font-size: 2.6rem;
    }
  }
}
