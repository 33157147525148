@import "../App.scss";

.cookies {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: rgba($color: $white, $alpha: 1);
  padding: 1.2rem;
  z-index: 100;
  @include flex(row, center, center);
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  gap: 1.5rem;
  border-radius: 5px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  animation-delay: 0.5s;
  animation: cookiesAnimation 0.5s ease;
  max-width: 90vw;
  z-index: 10000000000;

  @media screen and (max-width: 768px) {
    padding: 1rem;
    gap: 1rem;
  }

  &.cookies-hidden {
    display: none;
  }

  .cookies-description {
    gap: 1rem;
    font-size: 0.85rem;
    font-weight: 400;
    color: $gray-3;
    max-width: 20rem;
    line-height: 1.2;
    height: 100%;

    span {
      text-decoration: underline;
      cursor: pointer;
      font-weight: 500;
    }
  }

  .cookies-buttons {
    @include flex(column, center, center);
    gap: 0.5rem;

    button {
      padding: 0.7rem 1rem;
      font-size: 0.9rem;
      font-weight: 400;
      width: 100%;
      height: 2.5rem;
      color: $white;
      border: none;
      border-radius: 0.3rem;
      cursor: pointer;
      @include flex(row, center, center);
      transition: all 0.3s ease;
      span {
        margin-right: 0.3rem;
        font-weight: 500;
        font-size: 0.9rem;
      }

      &.cookies-accept {
        background-color: $primary;
        border: 1px solid $primary;

        &:hover {
          background-color: lighten($color: $primary, $amount: 10%);
        }
      }

      &.cookies-settings {
        background-color: $gray-14;
        border: 1px solid $gray-13;
        span {
          color: $black;
          font-weight: 500;
        }
      }
    }
  }
}

@keyframes cookiesAnimation {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
