@import "../App.scss";

.project {
  margin-bottom: 10vh;
  .project__image {
    width: 100%;
    height: 60vh;
    overflow: hidden;
    padding: 0 12.5%;
    position: relative;

    @media only screen and (max-width: 1300px) {
      padding: 0 5%;
    }

    img {
      width: 100%;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
    }
  }

  .info {
    @include flex(column, center, center);
    width: 100%;
    padding: 5vh 12.5%;

    @media only screen and (max-width: 1300px) {
      padding: 5vh 5%;
    }

    h2 {
      font-size: 1.2rem;
      font-weight: 600;
      color: $black;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
      span {
        color: $primary;
      }

      @media only screen and (max-width: 1300px) {
        font-size: 1rem;
        text-align: center;
        max-width: 100%;
      }
    }

    h1 {
      font-size: 2.8rem;
      color: $black;
      margin-bottom: 0.5rem;
      margin-bottom: 1.5rem;
      span {
        color: $primary;
      }

      @media only screen and (max-width: 1300px) {
        font-size: 2.4rem;
        text-align: center;
        max-width: 100%;
      }
    }

    .description {
      font-size: 0.95rem;
      line-height: 1.3;
      font-weight: 400;
      color: $dark-gray;
      max-width: 60%;
      text-align: left;
      margin-bottom: 1rem;
      text-align: center;
      @media only screen and (max-width: 1300px) {
        font-size: 0.85rem;
        max-width: 90%;
      }
    }

    .address,
    .year {
      font-size: 1rem;
      color: $black;
      font-weight: 600;
      max-width: 60%;
      text-align: left;
      margin-top: 1rem;

      @media only screen and (max-width: 1300px) {
        font-size: 0.85rem;
        max-width: 90%;
      }
    }
  }

  .project__content {
    @include flex(column, center, center);
    width: 100%;
    padding: 5vh 12.5%;
    overflow: hidden;
    gap: 5vh;
    @media only screen and (max-width: 1300px) {
      padding: 5vh 5%;
    }
    .slide {
      height: 60vh;
      width: 100%;
      @include flex(row, center, center);
      @media only screen and (max-width: 1300px) {
        @include flex(column, center, center);
        height: auto;
      }

      .image {
        width: 50%;
        height: 100%;
        overflow: hidden;
        @media only screen and (max-width: 1300px) {
          width: 100%;
          height: 50vh;
        }

        img {
          width: 100%;
          height: 100% !important;
          object-fit: cover;
          object-position: center;
        }

        video {
          width: 100%;
          height: 100% !important;
          object-fit: cover;
          object-position: center;
        }
      }

      .content {
        @include flex(column, center, flex-start);
        width: 50%;
        padding-left: 5%;
        height: 100%;
        @media only screen and (max-width: 1300px) {
          width: 100%;
          height: auto;
          padding: 5vh 5%;
        }

        h1 {
          font-size: 2.6rem;
          color: $black;
          margin-bottom: 2rem;
          text-align: left;

          @media only screen and (max-width: 1300px) {
            font-size: 2.4rem;
            text-align: center;
            max-width: 100%;
          }
        }

        p {
          font-size: 13px;
          color: $black;
          max-width: 100%;
          text-align: left;
          margin-top: 0.5rem;

          @media only screen and (max-width: 1300px) {
            font-size: 0.8rem;
            max-width: 100%;
          }
        }
      }

      &.reverse {
        @include flex(row-reverse, center, center);
        @media only screen and (max-width: 1300px) {
          @include flex(column, center, center);
        }

        .content {
          padding-right: 5%;
          padding-left: 0;
        }
      }
    }
  }

  .projects {
    width: 100%;
    padding: 0 12.5%;
    @include flex(column, center, center);
    position: relative;
    padding-bottom: 77vh;
    margin-top: 10vh;

    @media only screen and (max-width: 1300px) {
      padding: 0 5%;
      padding-bottom: 85vh;
      padding-top: 5vh;
    }

    h1 {
      font-size: 2.6rem;
      color: $dark-gray;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
      text-transform: uppercase;
      margin-bottom: 1.5rem;
      span {
        color: $primary;
      }

      @media only screen and (max-width: 1300px) {
        font-size: 2.5rem;
        text-align: center;
        max-width: 100%;
        transform: translateY(-5vh);
      }
    }

    p {
      text-align: center;
      max-width: 40%;
      font-size: 0.9rem;
      @media only screen and (max-width: 1300px) {
        font-size: 0.8rem;
        max-width: 90%;
      }
    }
  }
}
