@import "../App.scss";

.home {
  .hero {
    width: 100%;
    height: 100vh;
    background-color: $white;
    position: relative;
    @include flex(column, center, center);
    z-index: 0;
    @media only screen and (max-width: 1300px) {
      height: 82vh;
    }

    .slide {
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-in-out;
      position: absolute;
      left: 0;
      top: 0;

      &.active {
        .slide__content {
          opacity: 1;
          z-index: 2;
          transform: translateY(50%) translateX(-0%);
          transition: all 0.3s ease-in-out;
        }

        .slide__image {
          transform: translateX(0%);
          opacity: 1;
          transition: all 0.3s ease-in-out;
          z-index: 1;
        }
      }

      .slide__image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        opacity: 0.5;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: bottom;
          position: relative;
          display: block;
          filter: brightness(0.6);
        }
      }

      .slide__content {
        position: absolute;
        bottom: 57%;
        left: 12.5vw;
        transform: translateY(60%) translateX(0%);
        @include flex(column, space-between, flex-start);
        transition: all 0.3s ease-in-out;
        z-index: 6;
        opacity: 0;
        gap: 0.5rem;

        @media only screen and (max-width: 1300px) {
          width: 90%;
          @include flex(column, center, center);
          bottom: 50%;
          left: 5vw;
        }

        h1 {
          font-size: 4.5rem;
          color: $black;
          color: $white;
          line-height: 1.1;
          width: 1000px;
          font-weight: 800;
          text-align: left;
          text-transform: uppercase;
          animation: slideTitle 0.6s cubic-bezier(0.075, 0.82, 0.165, 1)
            forwards;
          margin-bottom: 0.5rem;

          span {
            color: $primary;
          }

          @media only screen and (max-width: 1300px) {
            font-size: 2.5rem;
            width: 100%;
            text-align: center;
          }
        }

        h2 {
          font-size: 23px;
          text-transform: uppercase;
          font-weight: 400;
          color: $light-gray;
          margin-bottom: 0.5rem;
          width: 100%;
          text-align: left;
          animation: slideSubTitle 0.6s cubic-bezier(0.075, 0.82, 0.165, 1)
            forwards;

          @media only screen and (max-width: 1300px) {
            font-size: 17px;
            width: 100%;
            text-align: center;
          }
        }

        p {
          font-size: 15px;
          font-weight: 300;
          line-height: 1.5;
          color: $light-gray;
          transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
          text-align: left;

          @media only screen and (max-width: 1300px) {
            font-size: 12px;
            width: 100%;
            text-align: center;
          }
        }

        .slide__description {
          @include flex(column, flex-start, flex-start);
          gap: 1rem;
          padding-top: 0.4rem;
          line-height: 1.1;
          text-align: center;
          width: 60%;
          animation: slideSubTitle 0.6s cubic-bezier(0.075, 0.82, 0.165, 1)
            forwards;
          @media only screen and (max-width: 1300px) {
            width: 90%;
          }
        }
      }
    }

    .benefits {
      z-index: 10;
      width: 100%;
      position: relative;
      @include flex(row, flex-start, center);
      gap: 2rem;
      width: 75%;
      transform: translateY(35vh);

      @media only screen and (max-width: 1300px) {
        display: none;
      }

      .benefit {
        @include flex(column, flex-start, flex-start);
        gap: 0.7rem;
        width: calc(100% / 3);
        background-color: rgba($color: #000000, $alpha: 0.6);
        padding: 1.5rem;
        height: 100% !important;
        h3 {
          font-size: 22px;
          color: $white;
          text-transform: uppercase;
          font-weight: 400;
          margin-bottom: 0.5rem;

          @media only screen and (max-width: 1300px) {
            font-size: 17px;
          }
        }

        p {
          font-size: 12px;
          color: $white;
          font-weight: 200;
          line-height: 1.5;
          width: 90%;

          @media only screen and (max-width: 1300px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  .about-us {
    @include flex(row, flex-start, center);
    width: 100%;
    z-index: 10000;
    height: 75vh;
    position: relative;
    margin-bottom: 5vh;
    background-color: $dark-gray;
    @media only screen and (max-width: 1300px) {
      height: 100%;
      padding: 0 5%;
    }

    @media only screen and (max-width: 768px) {
      height: 100%;
      padding: 0 5%;
      flex-direction: column;
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 50%;
      left: 0;
      top: 50%;
      z-index: 0;
    }

    .content {
      @include flex(column, center, flex-start);
      z-index: 2;
      width: 50%;
      padding: 2rem 3rem;
      padding-left: 12.5vw;

      @media only screen and (max-width: 1300px) {
        width: 100%;
        padding: 2rem 0rem;
        transform: translateX(0%);
        box-shadow: none;
      }

      h1 {
        font-size: 3rem;
        font-weight: 700;
        text-transform: uppercase;
        color: $white;
        margin-bottom: 1.5rem;

        @media only screen and (max-width: 1300px) {
          font-size: 2.5rem;
          width: 100%;
          text-align: left;
        }
      }

      p {
        font-size: 0.9rem;
        color: rgba($color: $white, $alpha: 0.8);
        margin-bottom: 2.5rem;
        max-width: 90%;
        line-height: 1.6;

        @media only screen and (max-width: 1300px) {
          max-width: 100%;
        }

        @media only screen and (max-width: 768px) {
          font-size: 0.9rem;
        }
      }

      .info {
        @include flex(row, flex-start, flex-start);
        width: 100%;
        margin-bottom: 2.5rem;

        a {
          @include flex(row, flex-start, center);
          gap: 1.5rem;
          text-decoration: none;
          color: $light-gray;
          padding: 0 1rem;
          width: 100%;
          svg {
            height: 2.3vh;
            width: 2.3vh;
            color: $light-gray;
          }

          span {
            font-size: 1rem;
            font-weight: 200;
            color: $light-gray;
          }
        }
      }

      .references {
        @include flex(row, flex-start, center);
        border: 1px solid #5c5c5c;
        background-color: #363636;

        .reference {
          @include flex(column, flex-start, flex-start);
          gap: 0.5rem;
          padding: 1.5rem 3rem;
          border-right: 1px solid #5c5c5c;

          &:last-child {
            border-right: none;
          }

          h3 {
            font-size: 3rem;
            color: #ffffff;
            text-align: center;
            width: 100%;
          }

          h4 {
            text-transform: uppercase;
            font-size: 1rem;
            color: #dddddd;
            font-weight: 300;
            text-align: center;
            width: 100%;
          }
        }
      }
    }

    .image {
      height: 100%;
      padding: 0;
      width: 50%;
      position: relative;
      overflow: hidden;
      padding-right: 5vw;

      @media only screen and (max-width: 1300px) {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .contact {
    width: 100%;
    @include flex(row, flex-start, flex-start);
    background-color: $light-gray;
    background-size: 500% 500%;
    animation: gradientMove 5s linear infinite;
    border-bottom: 1px solid #383838;
    border-top: 1px solid #e9e9e9;

    @media only screen and (max-width: 1000px) {
      height: 100%;
      @include flex(column, center, center);
    }

    .form {
      @include flex(column, flex-start, flex-start);
      padding: 7vh 2rem;
      width: 50%;
      background-color: $white;
      z-index: 5;
      height: 100%;
      padding-right: 12.5%;
      gap: 1rem;
      height: 100%;
      @media only screen and (max-width: 1300px) {
        padding-right: 5vw;
      }

      @media only screen and (max-width: 1000px) {
        width: 100%;
        padding: 3rem 5%;
        @include flex(column, flex-start, flex-start);
      }

      h1 {
        font-size: 33px;
        color: $dark-gray;

        @media only screen and (max-width: 1300px) {
          font-size: 20px;
          width: 100%;
          text-align: left;
        }

        span {
          color: $primary;
        }
      }

      input {
        width: 100%;
        padding: 0.7rem 1rem;
        border: 1px solid $gray;
        outline: none;
        font-size: 13px;
        color: $black;
        transition: all 0.3s ease-in-out;

        &:focus {
          border: 1px solid $primary;
        }
      }

      .row {
        @include flex(row, center, center);
        width: 100%;
        gap: 0.5rem;
      }

      textarea {
        width: 100%;
        padding: 1rem 1rem;
        border: 1px solid $gray;
        outline: none;
        font-size: 15px;
        color: $black;
        transition: all 0.3s ease-in-out;
        resize: none;
        min-height: 15vh;

        &:focus {
          border: 1px solid $primary;
        }
      }

      p {
        margin-top: 1rem;
        font-size: 13px;
        width: 100%;
        color: $dark-gray;
      }

      .btn {
        margin-top: 0.5rem;
        font-size: 13px;
        text-transform: uppercase;
        background-color: $dark-gray;
        border-radius: 0.1rem;
      }

      .checkbox {
        @include flex(row, flex-start, flex-start);
        gap: 1rem;
        width: 100%;

        input {
          width: 1rem;
          height: 1rem;
        }

        label {
          font-size: 13px;
          color: $dark-gray;
          width: 100%;
        }
      }
    }

    ul {
      padding: 1rem 2rem;
      margin: 0;
      gap: 1rem;
      @include flex(column, flex-start, flex-start);

      li {
        font-size: 20px;
        font-weight: 500;
        color: $dark-gray;

        span {
          margin-left: 0.5rem;
          font-weight: 400;
          font-size: 20px;
          color: $dark-gray;
        }
      }
    }

    .info {
      @include flex(column, flex-start, flex-start);
      padding: 7vh;
      width: 50%;
      padding-left: 12.5%;
      color: $white;
      height: 100%;
      gap: 2rem;

      @media only screen and (max-width: 1300px) {
        padding-left: 5vw;
      }

      @media only screen and (max-width: 1000px) {
        width: 100%;
        padding: 4rem 5%;
        height: auto;
        @include flex(column, flex-start, flex-start);
      }

      h1 {
        font-size: 38px;
        color: $dark-gray;

        @media only screen and (max-width: 1300px) {
          font-size: 2.5rem;
          width: 100%;
          text-align: left;
        }
      }

      p {
        font-size: 15px;
        color: $dark-gray;
        font-weight: 400;
        width: 70%;
        line-height: 1.6;
      }

      img {
        object-fit: contain;
        max-width: 70%;
      }
    }

    .socials {
      @include flex(row, center, center);
      gap: 0.5rem;

      img {
        height: 3vh;
        width: 3vh;
        cursor: pointer;
      }
    }
  }

  .devices {
    @include flex(column, center, center);
    width: 100%;
    padding: 5vh 12.5%;
    overflow: hidden;

    @media only screen and (max-width: 1300px) {
      padding: 5vh 5%;
    }

    h1 {
      font-size: 2.6rem;
      color: $dark-gray;
      margin-bottom: 2rem;
      text-transform: uppercase;

      @media only screen and (max-width: 1300px) {
        font-size: 2.5rem;
        width: 100%;
        text-align: center;
        margin-bottom: 1rem;
      }

      span {
        color: $primary;
      }
    }

    p {
      text-align: center;
      max-width: 40%;
      font-size: 0.85rem;
      @media only screen and (max-width: 1300px) {
        max-width: 90%;
      }
    }

    .devices__container {
      @include flex(row, center, center);
      gap: 1rem;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 3rem;
      overflow: hidden;

      @media only screen and (max-width: 1300px) {
        gap: 0.5rem;
        margin-top: 2rem;
      }

      .device {
        width: calc(50% - 0.5rem);
        height: 30vh;
        overflow: hidden;
        @include flex(row, center, center);
        background-color: #f1f1f1;
        border: 1px solid #e0e0e0;

        @media only screen and (max-width: 1300px) {
          width: calc(50% - 0.5rem);
        }

        @media only screen and (max-width: 1000px) {
          width: calc(100%);
        }

        .image {
          width: 50%;
          height: 100%;
          overflow: hidden;
          position: relative;

          img {
            width: 100%;
            height: 100% !important;
            object-fit: cover;
            object-position: center;
          }
        }

        .content {
          height: 100%;
          width: 50%;
          padding: 1.5rem;
          @include flex(column, space-between, flex-start);

          .top {
            @include flex(column, flex-start, flex-start);
            width: 100%;

            h3 {
              font-size: 2.2rem;
              color: $black;
              margin-bottom: 0.5rem;
            }

            p {
              font-size: 0.9rem;
              color: $dark-gray;
              max-width: 100%;
              text-align: left;
            }
          }

          .bottom {
            @include flex(row, flex-start, center);
            width: 100%;
            gap: 1rem;

            @media only screen and (max-width: 1300px) {
              gap: 0.5rem;
              @include flex(column-reverse, flex-start, flex-start);
            }

            .outside__btn {
              font-size: 0.83rem;
              background-color: $dark-gray;
              color: $white;
              padding: 0.65rem 1.1rem;
            }
          }
        }
      }
    }
  }

  .projects {
    width: 100%;
    padding: 0 12.5%;
    @include flex(column, center, center);
    position: relative;
    padding-bottom: 78vh;
    margin-top: 10vh;

    @media only screen and (max-width: 1300px) {
      padding: 0 5%;
      padding-bottom: 78vh;
      padding-top: 5vh;
    }

    @media only screen and (max-width: 768px) {
      padding-bottom: 88vh;
    }

    @media (max-width: 400px) {
      padding-bottom: 95vh;
    }

    h1 {
      font-size: 2.6rem;
      color: $dark-gray;
      margin-bottom: 1rem;
      text-transform: uppercase;

      @media only screen and (max-width: 1300px) {
        font-size: 2.5rem;
        width: 100%;
        text-align: center;
      }

      span {
        color: $primary;
      }
    }

    p {
      text-align: center;
      max-width: 40%;
      font-size: 0.9rem;

      @media only screen and (max-width: 1300px) {
        font-size: 0.8rem;
        max-width: 90%;
      }
    }
  }

  .contact__home__section {
    width: 100%;
    padding: 5vh 12.5%;
    position: relative;
    margin-top: 10vh;
    @include flex(column, center, center);

    @media only screen and (max-width: 1300px) {
      padding: 5vh 5%;
    }

    .image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $primary-dark;
        opacity: 0.8;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    h1 {
      font-size: 2.6rem;
      color: $white;
      margin-bottom: 1rem;
      text-transform: uppercase;

      @media only screen and (max-width: 1300px) {
        font-size: 2.6rem;
        width: 100%;
        text-align: center;
      }

      span {
        font-size: 2.6rem;

        @media only screen and (max-width: 1300px) {
          font-size: 2.6rem;
        }
      }
    }

    p {
      font-size: 0.9rem;
      color: $white;
      margin-bottom: 1rem;
      text-align: center;
      max-width: 40%;

      @media only screen and (max-width: 1300px) {
        font-size: 0.85rem;
        max-width: 90%;
      }
    }

    .btn {
      background-color: $white;
      color: $black;
      font-size: 0.9rem;
    }
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(-30rem);
  }
  100% {
    transform: translateX(0rem);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(30rem);
  }
  100% {
    transform: translateX(0rem);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(-10rem);
  }
  100% {
    transform: translateY(0rem);
  }
}

@keyframes slideUpContent {
  0% {
    transform: translateY(90%) translateX(-50%);
  }
  100% {
    transform: translateY(50%) translateX(-50%);
  }
}

@keyframes slideService {
  0% {
    opacity: 0;
    transform: translateY(5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(-5vh);
  }
}

@keyframes slideSubTitle {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0rem);
  }
}

@keyframes slideTitle {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0rem);
  }
}

@keyframes gradientMove {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
