@import "../App.scss";

.carousel__buttons {
  position: absolute;
  bottom: 50vh;
  left: 50%;
  transform: translate(-50%, 0);
  width: 75%;
  padding: 0 2.5%;
  @include flex(row, space-between, center);
  z-index: 999;

  @media only screen and (max-width: 1300px) {
    width: 90%;
    bottom: 60vh;
    left: 5%;
    transform: translate(0, 0);
  }

  .carousel__button {
    position: absolute;
    left: 0;
    top: 50%;
    width: 3rem;
    height: 5rem;
    background-color: rgba($color: $white, $alpha: 0.8);
    z-index: 10;
    padding: 0.6rem;
    border: none;
    outline: none;
    cursor: pointer;
    transform: translateX(-10%);
    @include flex(row, center, center);

    svg {
      width: 100%;
      height: 100%;
      color: $black;
    }

    &.right {
      left: auto;
      right: 0;
      transform: translateX(10%);
    }
  }
}

.carousel {
  width: 75%;
  @include flex(row, flex-start, flex-start);
  height: 75vh;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  overflow: hidden;

  @media (max-width: 1300px) {
    width: 90%;
    height: 80%;
    bottom: 0%;
    left: 5%;
    transform: translate(0, 0);
  }

  @media (max-width: 768px) {
    height: 85vh;
  }

  @media (max-width: 400px) {
    height: 92vh;
  }

  .inner__carousel {
    @include flex(row, flex-start, center);
    height: 100%;
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1.5s;

    .project {
      width: calc(75vw / 3);
      @include flex(column, flex-start, flex-start);
      padding: 0 0.5rem;
      gap: 0;
      text-decoration: none;
      color: $dark-gray;

      &:hover {
        .image {
          img {
            transition: all 0.3s ease-in-out;
            transform: scale(1.1);
          }
        }
      }

      @media (max-width: 1300px) {
        width: calc(90vw / 3);
      }

      @media (max-width: 1000px) {
        width: calc(90vw / 2);
      }

      @media (max-width: 768px) {
        width: 90vw;
        height: 85vh;
      }

      @media (max-width: 400px) {
        height: 92vh;
      }

      .image {
        height: 50vh;
        width: 100%;
        overflow: hidden;
        position: relative;
        border-radius: 0.2rem;
        box-shadow: 0 0 6px rgba($color: $black, $alpha: 0.25);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 0.2rem;
        }
      }

      .content {
        @include flex(column, flex-start, flex-start);
        width: calc(100%);
        gap: 1rem;
        height: 40%;
        padding: 1.5rem 0;
        padding-bottom: 0;
        position: relative;

        h3 {
          font-size: 23px;
          color: $dark-gray;

          @media (max-width: 1300px) {
            font-size: 18px;
          }
        }

        .project__type {
          padding: 0.25rem 0.7rem;
          background-color: #dfdfdf;
          color: #3d3d3d;
          font-size: 13px;
          font-weight: 600;
          z-index: 10;
          border-radius: 0.3rem;
        }

        p {
          font-size: 12px;
          color: $dark-gray;
          max-width: 100%;
          text-align: left;
          line-height: 1.5;
          padding-bottom: 2rem;
          position: relative;
        }

        .outside__btn {
          position: absolute;
          bottom: 0;
          font-size: 0.85rem;
          background-color: $dark-gray;
          color: $white;
          padding: 0.6rem 1.2rem;
          margin-top: 0.5rem;
          display: none;

          @media (max-width: 1300px) {
            font-size: 0.78rem;
          }
        }
      }
    }
  }
}
