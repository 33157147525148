@import "../App.scss";

.contact {
  .contact {
    width: 100%;
    @include flex(row, flex-start, flex-start);
    background-color: $light-gray;
    background-size: 500% 500%;
    animation: gradientMove 5s linear infinite;
    border-bottom: 1px solid #383838;
    border-top: 1px solid #e9e9e9;

    @media only screen and (max-width: 1000px) {
      height: 100%;
      @include flex(column, center, center);
    }

    .form {
      @include flex(column, flex-start, flex-start);
      padding: 7vh 2rem;
      width: 50%;
      background-color: $white;
      z-index: 5;
      height: 100%;
      padding-right: 12.5%;
      gap: 1rem;
      height: 100%;

      @media only screen and (max-width: 1300px) {
        padding-right: 5vw;
      }

      @media only screen and (max-width: 1000px) {
        width: 100%;
        padding: 3rem 5%;
        @include flex(column, flex-start, flex-start);
      }

      h1 {
        font-size: 33px;
        color: $dark-gray;

        @media only screen and (max-width: 1300px) {
          font-size: 20px;
          width: 100%;
          text-align: left;
        }

        span {
          color: $primary;
        }
      }

      input {
        width: 100%;
        padding: 0.7rem 1rem;
        border: 1px solid $gray;
        outline: none;
        font-size: 13px;
        color: $black;
        transition: all 0.3s ease-in-out;

        &:focus {
          border: 1px solid $primary;
        }
      }

      .row {
        @include flex(row, center, center);
        width: 100%;
        gap: 0.5rem;
      }

      textarea {
        width: 100%;
        padding: 1rem 1rem;
        border: 1px solid $gray;
        outline: none;
        font-size: 15px;
        color: $black;
        transition: all 0.3s ease-in-out;
        resize: none;
        min-height: 15vh;

        &:focus {
          border: 1px solid $primary;
        }
      }

      p {
        margin-top: 1rem;
        font-size: 13px;
        width: 100%;
        color: $dark-gray;
      }

      .btn {
        margin-top: 0.5rem;
        font-size: 13px;
        text-transform: uppercase;
        background-color: $dark-gray;
        border-radius: 0.1rem;
      }

      .checkbox {
        @include flex(row, flex-start, flex-start);
        gap: 1rem;
        width: 100%;

        input {
          width: 1rem;
          height: 1rem;
        }

        label {
          font-size: 13px;
          color: $dark-gray;
          width: 100%;
        }
      }
    }

    ul {
      padding: 1rem 2rem;
      margin: 0;
      gap: 1rem;
      @include flex(column, flex-start, flex-start);

      li {
        font-size: 20px;
        font-weight: 500;
        color: $dark-gray;

        span {
          margin-left: 0.5rem;
          font-weight: 400;
          font-size: 20px;
          color: $dark-gray;
        }
      }
    }

    .info {
      @include flex(column, flex-start, flex-start);
      padding: 7vh;
      width: 50%;
      padding-left: 12.5%;
      color: $white;
      height: 100%;
      gap: 2rem;

      @media only screen and (max-width: 1300px) {
        padding-left: 5vw;
      }

      @media only screen and (max-width: 1000px) {
        width: 100%;
        padding: 4rem 5%;
        height: auto;
        @include flex(column, flex-start, flex-start);
      }

      h1 {
        font-size: 38px;
        color: $dark-gray;

        @media only screen and (max-width: 1300px) {
          font-size: 2.5rem;
          width: 100%;
          text-align: left;
        }
      }

      p {
        font-size: 15px;
        color: $dark-gray;
        font-weight: 400;
        width: 70%;
        line-height: 1.6;
      }

      img {
        object-fit: contain;
        max-width: 70%;
      }
    }

    .socials {
      @include flex(row, center, center);
      gap: 0.5rem;

      img {
        height: 3vh;
        width: 3vh;
        cursor: pointer;
      }
    }
  }
}
