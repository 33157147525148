@import "../App.scss";

.footer {
  @include flex(column, center, center);
  width: 100%;
  position: relative;
  background-color: #131313;

  .box-container {
    width: 100%;
    padding: 5vh 12.5%;
    @include flex(row, flex-start, flex-start);
    flex-wrap: wrap;
    padding-bottom: 5vh;
    border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.1);

    @media (max-width: 1300px) {
      padding: 3vh 5%;
    }

    .box {
      @include flex(column, flex-start, flex-start);
      margin: 1rem 0;
      width: calc(100% / 5);

      @media (max-width: 1400px) {
        width: 50%;
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      &.first {
        width: calc((100% / 5) * 2);
        @media (max-width: 1400px) {
          width: 50%;
        }

        @media (max-width: 768px) {
          width: 100%;
        }
        .logo {
          height: 13vh;
          object-fit: contain;
          margin-bottom: 1rem;

          @media (max-width: 768px) {
            width: 100%;
          }
        }

        p {
          font-size: 0.9rem;
          font-weight: 300;
          line-height: 1.6;
          padding-top: 0.6rem;
          padding-bottom: 1.5rem;
          color: $gray;
          width: 70%;
          text-align: left;

          @media (max-width: 768px) {
            width: 100%;
          }
        }

        .socials {
          @include flex(row, flex-start, flex-start);
          gap: 1rem;

          @media (max-width: 768px) {
            width: 100%;
            @include flex(row, flex-start, center);
          }

          img {
            width: 2.1rem;
            height: 2.1rem;
            cursor: pointer;
          }
        }
      }

      .logo {
        height: 13vh;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      h3 {
        font-size: 1.7rem;
        font-weight: 300;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
        color: $white;
      }

      ul {
        @include flex(column, flex-start, flex-start);
        width: calc(100% - 1rem);
        gap: 1rem;
        list-style: none;
        padding-left: 0.1rem;

        @media (max-width: 768px) {
          width: 100%;
        }

        li {
          color: $gray;
          font-size: 0.85rem;
          font-weight: 200;
          a {
            color: $gray;
            font-size: 0.85rem;
            font-weight: 200;
            text-decoration: none;

            &:hover {
              color: $white;
              text-decoration: underline;
            }
          }
        }

        .btn {
          padding: 0.65rem 1.1rem;
          margin-top: 0.5rem;
          font-size: 0.9rem;
          font-weight: 300;
          text-transform: uppercase;
          background-color: transparent;
          color: $white;
          border: 1px solid $white;
        }
      }
    }
  }

  .rights {
    width: 100%;
    padding: 0 12.5%;
    background-color: $dark-gray;
    @include flex(column, center, center);
    gap: 0.5rem;

    @media (max-width: 1300px) {
      padding: 0 5%;
    }

    .bottom-container {
      @include flex(row, center, center);
      gap: 0.5rem;
      position: relative;
      width: 100%;

      .links {
        position: absolute;
        left: 0;

        @include flex(row, center, center);

        @media (max-width: 768px) {
          display: none;
        }

        a {
          font-size: 0.7rem;
          color: #ffffffbe;
          margin-right: 0.5rem;
          text-decoration: underline;

          &:hover {
            color: $white;
          }
        }
      }
    }

    hr {
      width: 100%;
      border: none;
      outline: none;
      border-bottom: 1px solid $light-gray;
      opacity: 0.6;
      display: none;
    }

    p {
      font-size: 0.7rem;
      color: $gray;
      padding: 1rem 0;
    }
  }
}
